<template>
  <div>
    <subscription-modal
      v-if="car"
      :show="showSubscriptionModal"
      type="CAR_POOL"
      :can-use-car-pool="canUseCarPool"
      :has-additional-data="carAdditionalInfo != null"
      :car-id="car._id"
      @close="closeModal"
    />
    <NotSubscribedModal
      v-if="car"
      :show="showNotSubscribedModal"
      type="CAR_POOL"
      :can-use-car-pool="canUseCarPool"
      :has-additional-data="carAdditionalInfo != null"
      :car-id="car._id"
      @close="showNotSubscribedModal = false"
    />
    <lightbox
      v-if="showCarImageModal && carImage"
      :images="carAdditionalInfo.images"
      :current-image="carImage"
      size="lg"
      :closable="true"
      @close="showCarImageModal = false"
      @removeImage="removeImage(index)"
    />
    <ca-header
      :loading="loading"
      :column-layout="true"
    >
      <template #heading>
        <div class="d-flex">
          <h3 class="mb-0 d-inline">
            <span v-if="carAdditionalInfo">{{ carAdditionalInfo.description }}</span>
            <span v-else>{{ car && car.licensePlate }}</span>
          </h3>
          <span
            v-if="car.isInPool"
            class="badge badge-pill badge-success text-white align-self-center mt-1 ml-3"
          >
            <i class="far fa-check" /> im Fahrzeugpool
          </span>
        </div>
      </template>
      <template #buttonSpace>
        <b-button
          id="carRemove"
          :disabled="savePending"
          class="btn btn-danger text-white mr-2 px-2"
        >
          <i class="far fa-trash-alt" />
        </b-button>

        <b-popover
          target="carRemove"
          placement="bottom"
          title="Sind Sie sich sicher?"
          triggers="click blur"
        >
          <p>
            Die Fahrzeugdaten werden dadurch unwiderruflich gelöscht!
          </p>
          <button
            class="btn btn-danger"
            @click="deleteCar"
          >
            Bestätigen
          </button>
        </b-popover>
        <router-link
          v-if="me.role === 10"
          :to="{name: 'CarEdit', params: {carId: car._id}}"
          class="btn btn-secondary text-primary mr-2"
        >
          <i class="far fa-pen" /> bearbeiten
        </router-link>
        <button
          v-if="showCarPool"
          class="btn btn-primary text-white"
          @click="toggleIsInPool"
        >
          {{ car.isInPool ? 'Aus Fahrzeugpool entfernen': 'Im Fahrzeugpool veröffentlichen' }}
        </button>
        <button
          v-else
          class="btn btn-primary text-white"
          @click="showNotSubscribedModal = true"
        >
          Im Fahrzeugpool veröffentlichen
        </button>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div class="col-12 col-xl-8 col-xxl-6">
          <ca-card
            v-if="carAdditionalInfo"
            class="mb-3"
          >
            <template #header>
              <h4>
                Bilder
              </h4>
            </template>
            <template #body>
              <div
                v-if="carAdditionalInfo && carAdditionalInfo.images"
                class="form-row"
              >
                <div class="col-12 col-md-4">
                  {{ carAdditionalInfo.images.length }} von {{ maxImages }} Bildern hochgeladen
                </div>
                <div class="col-12 col-md-8">
                  <div class="form-row">
                    <div
                      v-for="(image, index) of carAdditionalInfo.images"
                      :key="`test-${index}`"
                      class="col-4 mb-2"
                    >
                      <div class="img-wrapper">
                        <img
                          :src="image.downloadUrl"
                          alt=""
                        >
                        <div
                          class="openModal"
                          @click="openCarImageModal(image, index)"
                        />
                      </div>
                    </div>
                    <div
                      v-if="uploadPending"
                      class="col-4"
                    >
                      <div class="img-wrapper d-flex align-items-center justify-content-center bg-gray-3">
                        <i class="fas fa-circle-notch fa-spin text-primary" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <basic-file-upload
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                placeholder="Bilder hochladen"
                :horizontal="true"
                multiple
                accept="image/*"
                label="Bilder hochladen"
                :align-label-center="false"
                hide-file-list
                @uploaded="addImage"
                @upload-pending="uploadPending = $event"
              />
            </template>
          </ca-card>

          <car-data-detail :car="car" />
          <ca-card
            v-if="!car.carAdditionalInfoId"
            class="mb-3 border-primary"
          >
            <template #body>
              <div
                class="row"
              >
                <div class="col-auto">
                  <i class="fal fa-file-alt fa-4x text-primary" />
                </div>
                <div class="col">
                  <h4 class="mb-2">
                    Weitere Daten eingeben
                  </h4>
                  <p class="mb-2">
                    Hier haben Sie die Möglichkeit weitere Daten zu ihrem Fahrzeug eintragen um Ihr Fahrzeug im Fahrzeugpool repräsentieren zu können oder ein Verkaufsschild generieren zu lassen.
                  </p>
                  <router-link
                    v-if="canUseCarPool"
                    :to="{name: 'CarAdditionalData', params: { carId: car._id }}"
                    class="btn btn-primary"
                  >
                    <i class="far fa-plus" /> Weitere Daten hinzufügen
                  </router-link>
                  <button
                    v-else
                    class="btn btn-primary"
                    @click="showNotSubscribedModal = true"
                  >
                    <i class="far fa-plus" /> Weitere Daten hinzufügen
                  </button>
                </div>
              </div>
            </template>
          </ca-card>
          <car-additional-data-detail
            v-if="carAdditionalInfo"
            :car-additional-info="carAdditionalInfo"
          />

          <dev-info :data="{car, carAdditionalInfo}" />
        </div>
        <div class="col-12 col-xl-4">
          <RelatedOffers
            v-if="orders"
            :orders="orders"
            :params="{car}"
          />
          <ca-card
            v-if="productOptions.length > 0"
            class="mb-3"
          >
            <template #header>
              <h4 class="mb-0">
                Diese Produkte könnten passen:
              </h4>
            </template>
            <template #body>
              <div class="row">
                <div
                  v-for="product in productOptions"
                  :key="product.label"
                  class="col-12"
                >
                  <b-link :to="{ name: 'OrderCreate', params: { carId: car._id, product } }">
                    {{ product.label }}
                  </b-link>
                </div>
              </div>
            </template>
          </ca-card>
          <car-chat-list
            :heading="'Korrespondenzen aus dem Fahrzeugpool'"
            @currentChat="currentChat = $event"
          />
          <car-chat
            v-if="chats && chats[currentChat]"
            :close="true"
            :chat-id="chats[currentChat]._id"
            @close="currentChat = undefined"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DevInfo from '@/components/DevInfo'
import CaCard from '@/components/Card'
import formatDate from '@/filters/formatDate.js'
import formatOrderStatus from '../../filters/formatOrderStatus.js'
import SubscriptionModal from '@/components/Modals/SubscriptionModal'
import NotSubscribedModal from '@/components/Modals/NotSubscribedModal'
import Lightbox from '@/components/Lightbox'
import CarChatList from '@/components/CarChatList'
import CarChat from '@/components/CarChat'
import CarAdditionalDataDetail from '@/components/CarAdditionalData/CarAdditionalDataDetail'
import CarDataDetail from '@/components/CarDataDetail'
import BasicFileUpload from '@/components/BaseComponents/BasicFileUpload'
import moment from 'moment'
import transformRateList from '../../helpers/transformRateList'
import RelatedOffers from '@/components/RelatedOffers.vue'

export default {
  components: {
    CaHeader,
    DevInfo,
    CaCard,
    CarChatList,
    CarChat,
    BasicFileUpload,
    SubscriptionModal,
    Lightbox,
    CarAdditionalDataDetail,
    CarDataDetail,
    NotSubscribedModal,
    RelatedOffers
  },
  filters: {
    formatOrderStatus,
    formatDate,
    monthOptions (val) {
      switch (val) {
      case 0: return ''
      case 1: return 'Januar'
      case 2: return 'Februar'
      case 3: return 'März'
      case 4: return 'April'
      case 5: return 'Mai'
      case 6: return 'Juni'
      case 7: return 'Juli'
      case 8: return 'August'
      case 9: return 'September'
      case 10: return 'Oktober'
      case 11: return 'November'
      case 12: return 'Dezember'
      }
    }
  },
  data () {
    return {
      showSubscriptionModal: false,
      showNotSubscribedModal: false,
      showCarImageModal: false,
      loading: false,
      statusFilter: undefined,
      numberOfCarsInPool: 0,
      savePending: false,
      saveError: null,
      car: null,
      chat: undefined,
      currentChat: undefined,
      chats: undefined,
      rates: [],
      orders: null,
      maxImages: 15,
      additionalData: {},
      carAdditionalInfo: null,
      carImage: null,
      uploadPending: false
    }
  },
  computed: {
    carPoolLimit () {
      return this.me.subscriptions.reduce((prev, cur) => {
        if (new Date(cur.from) < Date.now() && new Date(cur.to) > Date.now() && cur.type === 'CAR_POOL') prev = prev + cur.limit
        return prev
      }, 0)
    },
    canUseCarPool () {
      return this.me.subscriptions.reduce((prev, cur) => {
        if (prev === true) return true
        if (new Date(cur.from) <= Date.now() && new Date(cur.to) >= Date.now() && cur.type === 'CAR_POOL') return true
        return false
      }, false)
    },
    showCarPool () {
      return this.car.isInPool || (this.numberOfCarsInPool < this.carPoolLimit && this.canUseCarPool && this.carAdditionalInfo != null)
    },
    me () {
      return this.$store.getters['auth/user'].user
    },
    baseQuery () {
      const baseQuery = {
        'car.chassi': this.car.chassi,
        userId: this.me._id
      }
      if (this.statusFilter !== undefined) {
        baseQuery.status = this.statusFilter
      }
      return baseQuery
    },
    productOptions () {
      return this.rates
        .reduce((products, rate) => {
          if (products.every(product => product.hn !== rate.product.hn)) {
            products.push(rate.product)
          }
          return products
        }, [])
        .map(product => ({
          label: `(${product.hn}) ${product.name}`,
          value: product.hn
        }))
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async removeImage (index) {
      this.carAdditionalInfo.imageIds.splice(index, 1)
      this.carAdditionalInfo.images.splice(index, 1)

      this.carAdditionalInfo = await this.$store.dispatch('car-additional-info/patch', [this.carAdditionalInfo._id, {
        imageIds: this.carAdditionalInfo.imageIds
      }])
      this.showCarImageModal = false
    },
    async addImage (files) {
      this.carAdditionalInfo.imageIds.push(...files.map(file => file._id))

      this.carAdditionalInfo = await this.$store.dispatch('car-additional-info/patch', [this.carAdditionalInfo._id, {
        imageIds: this.carAdditionalInfo.imageIds
      }])
    },
    formatTime (myDate) {
      const today = moment()
      if (moment(myDate).isSame(today, 'day')) {
        return formatDate(myDate, 'HH:mm')
      } else {
        return formatDate(myDate, 'DD.MM.YYYY')
      }
    },
    closeModal () {
      this.showSubscriptionModal = false
      this.showCarImageModal = false
      this.fetchData()
    },
    openSubscriptionModal () {
      this.showSubscriptionModal = true
    },
    openCarImageModal (image, index) {
      this.carImage = { image, index }
      this.showCarImageModal = true
    },
    async findRates () {
      if (this.car.hsn && this.car.tsn) {
        const hsnTsnRequest = await this.$store.dispatch('hsntsns/find', {
          query: {
            hsn: this.car.hsn,
            tsn: this.car.tsn
          }
        })
        const matchingCar = hsnTsnRequest.data[0]
        if (matchingCar) {
          this.$set(this.car, 'type', matchingCar.typ)
          this.$set(this.car, 'ccm', matchingCar.ccm)
          this.$set(this.car, 'kw', matchingCar.kw)
          this.$set(this.car, 'producer', matchingCar.producer)
        }
      }

      const requiredValuesSet = [this.car.kw, this.car.km, this.car.monthFromRegistrationDate]
        .every(value => value !== null && value !== undefined && value !== '')
      if (requiredValuesSet) {
        const rateRequest = await this.$store.dispatch('rates/find', {
          query: {
            $limit: 500,
            $own: true,
            kwVon: {
              $lte: this.car.kw
            },
            kwBis: {
              $gte: this.car.kw
            },
            kmVon: {
              $lte: this.car.km
            },
            kmBis: {
              $gte: this.car.km
            },
            preisVon: {
              $lte: this.car.price || 0
            },
            preisBis: {
              $gte: this.car.price || 0
            },
            alterVon: {
              $lte: this.car.monthFromRegistrationDate
            },
            alterBis: {
              $gte: this.car.monthFromRegistrationDate
            }
          }
        })
        this.rates = transformRateList(rateRequest)
      }
    },
    async deleteCar () {
      try {
        if (this.savePending) return
        this.savePending = true
        this.loading = true
        await this.$router.push({ name: 'CarList' })
        await this.$store.dispatch('car/remove', this.car._id)
      } catch (error) {
        this.saveError = error
        console.error(error)
      } finally {
        this.savePending = false
        this.loading = false
      }
    },
    toggleIsInPool () {
      this.car.isInPool = !this.car.isInPool
      this.save()
    },
    goToChat (i) {
      this.currentChat = i
    },
    async save () {
      try {
        if (this.savePending) return
        this.savePending = true
        await this.$store.dispatch('car/patch', [this.car._id, this.car])
      } catch (error) {
        this.saveError = error
        console.error(error)
      } finally {
        this.savePending = false
      }
    },
    setStatusFilter (statusCode) {
      this.statusFilter = statusCode
    },
    async fetchData () {
      try {
        if (this.loading) return
        this.loading = true
        this.car = await this.$store.dispatch('car/get', this.$route.params.carId)
        this.orders = (await this.$store.dispatch('orders/find', {
          query: this.baseQuery
        })).data
        this.chats = await this.$store.dispatch('car-chat/find', {
          query: {
            $client: {
              paginate: false
            },
            carId: this.car._id
          }
        })
        await this.findRates()
        this.numberOfCarsInPool = (await this.$store.dispatch('car/find', { query: { isInPool: true, userId: this.me._id, $limit: 0 } })).total
        if (this.car.carAdditionalInfoId != null) {
          this.carAdditionalInfo = await this.$store.dispatch('car-additional-info/get', this.car.carAdditionalInfoId)
        }
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.img-wrapper{
    width: 100%;
    height: 100px;
    overflow: hidden;
    border-radius: $border-radius;
    &:hover{
      .openModal{
        opacity: 0.7;
        cursor: pointer;
      }
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .openModal{
      transition: opacity 300ms ease;
      background-color: $primary;
      opacity: 0;
      border-radius: $border-radius;
      top: 0;
      bottom: 0;
      left: 5px;
      right: 5px;
      position: absolute;
    }
  }

</style>
