export default function transformRateList (rateResponse, filterConflicting = true) {
  let rates = rateResponse.data
  const products = rates.reduce((accProducts, rate) => {
    if (!accProducts.includes(rate.product.hn.toString())) {
      accProducts.push(rate.product.hn.toString())
    }
    return accProducts
  }, [])
  if (filterConflicting) {
    rates = rates
      .filter(rate => !rate.product.conflictingProducts ||
        !rate.product.conflictingProducts
          .map(conflictingProduct => conflictingProduct.toString())
          .some(conflictingProduct => products.includes(conflictingProduct))
      )
  }
  rates = rates
    .sort((a, b) => a.product.hn > b.product.hn ? 1 : -1)
  return rates
}
