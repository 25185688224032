<template>
  <modal
    v-if="show"
    title="Abonnement abschließen"
    :closable="false"
  >
    <template
      v-if="loading"
      #body
    >
      <i class="fas fa-circle-notch fa-spin" />
    </template>
    <template
      v-else
      #body
    >
      <h5>Abonement-Typ</h5>
      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <div
            class="border abo-typ-wrapper rounded text-center cursor-pointer py-3"
            :class="{'border-primary': subscription.type === 'CAR'}"
            @click="subscription.type = 'CAR'"
          >
            <div
              class="row"
              :class="{'text-primary': subscription.type === 'CAR', 'text-gray-5': subscription.type !== 'CAR'}"
            >
              <div class="col-12">
                <i class="far fa-2x fa-car-garage mx-auto" />
              </div>
              <div class="col-12">
                <p class="mb-0">
                  Fahrzeugverwaltung
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <div
            class="border abo-typ-wrapper rounded text-center cursor-pointer py-3"
            :class="{'border-primary': subscription.type === 'CAR_POOL'}"
            @click="subscription.type = 'CAR_POOL'"
          >
            <div
              class="row"
              :class="{'text-primary': subscription.type === 'CAR_POOL', 'text-gray-5': subscription.type !== 'CAR_POOL'}"
            >
              <div class="col-12">
                <i class="far fa-2x fa-car-building mx-auto" />
              </div>
              <div class="col-12">
                <p class="mb-0">
                  Fahrzeug Pool
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5 class="mt-4">
        Anzahl weiterer Fahrzeuge
      </h5>
      <div class="row">
        <div
          v-for="item in options.limit"
          :key="item.value"
          class="col-6 col-md mb-3"
        >
          <div
            class="border abo-typ-wrapper rounded text-center cursor-pointer py-3"
            :class="{'border-primary': subscription.limit === item.value}"
            @click="subscription.limit = item.value"
          >
            <div
              class="row"
              :class="{'text-primary': subscription.limit === item.value, 'text-gray-5': subscription.limit !== item.value}"
            >
              <div class="col-12">
                <h3 class="mb-0">
                  +{{ item.value }}
                </h3>
              </div>
              <div class="col-12">
                <p class="mb-0">
                  Fahrzeuge
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5 class="mt-4">
        Laufzeit
      </h5>
      <div class="row">
        <div
          v-for="item in options.to"
          :key="item.value"
          class="col-6 col-md mb-3"
        >
          <div
            class="border abo-typ-wrapper rounded text-center cursor-pointer py-3"
            :class="{'border-primary': subscription.to === item.value}"
            @click="subscription.to = item.value"
          >
            <div
              class="row"
              :class="{'text-primary': subscription.to === item.value, 'text-gray-5': subscription.to !== item.value}"
            >
              <div class="col-12">
                <h3 class="mb-0">
                  {{ item.amount }}
                </h3>
              </div>
              <div class="col-12">
                <p class="mb-0">
                  {{ item.unit }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <dev-info
        class="mt-3"
        :data="{subscription}"
      />
    </template>
    <template #footer>
      <div class="text-right">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          @click="$emit('close')"
        >
          Abbrechen
        </button>
        <button
          type="button"
          class="btn btn-success text-white"
          :disabled="!subscription.type || !subscription.limit || !subscription.to"
          @click="save"
        >
          Abschließen
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import DevInfo from '@/components/DevInfo'
import moment from 'moment'
export default {
  name: 'OrderModal',
  components: {
    Modal,
    DevInfo
  },
  model: {
    close: 'close'
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: null
    },
    userId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    subscription: {
      type: '',
      limit: undefined,
      to: undefined
    },
    loading: false,
    aboTyp: '',
    options: {
      type: [
        { label: 'Fahrzeugverwaltung', value: 'CAR' },
        { label: 'Fahrzeug Pool', value: 'CAR_POOL' }
      ],
      limit: [
        { label: '5 weitere Fahrzeuge', value: 5 },
        { label: '10 weitere Fahrzeuge', value: 10 },
        { label: '15 weitere Fahrzeuge', value: 15 },
        { label: '20 weitere Fahrzeuge', value: 20 }
      ],
      to: [
        { amount: '1', unit: 'Monat', value: moment().add(1, 'M').toString() },
        { amount: '2', unit: 'Monate', value: moment().add(2, 'M').toString() },
        { amount: '6', unit: 'Monate', value: moment().add(6, 'M').toString() },
        { amount: '1', unit: 'Jahr', value: moment().add(1, 'Y').toString() }
      ]
    }
  }),
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    }
  },
  watch: {
    show: 'reset'
  },
  methods: {
    async save () {
      try {
        if (!this.subscription.type || !this.subscription.limit || !this.subscription.to) return
        this.loading = true
        const sub = await this.$store.dispatch('subscription/create', this.subscription)

        let { subscriptionIds } = await this.$store.dispatch('users/get', this.userId)
        if (subscriptionIds === undefined) subscriptionIds = []

        subscriptionIds.push(sub._id)

        await this.$store.dispatch('users/patch', [this.userId, { subscriptionIds }])
        this.$emit('close')
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    reset () {
      this.subscription.type = ''
      this.subscription.limit = undefined
      this.subscription.to = undefined
      if (this.type != null) this.subscription.type = this.type
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.abo-typ-wrapper{
    transition: background-color 300ms;
    background-color: white;
  &:hover{
    transition: background-color 300ms;
    background-color: $secondary;
  }
}
</style>
