<script>
import feathers from '@/api'
export default {
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    link: null,
    keepAlifeTimeout: null
  }),
  watch: {
    file: {
      deep: true,
      handler: 'resetFile'
    }
  },
  created () {
    this.link = this.file.downloadUrl
    this.keepLinkAlife()
  },
  destroyed () {
    clearInterval(this.keepAlifeTimeout)
  },
  methods: {
    resetFile () {
      clearInterval(this.keepAlifeTimeout)
      this.link = this.file.downloadUrl
      this.keepLinkAlife()
    },
    keepLinkAlife () {
      const requestBeforeExpire = 5 // time in seconds before the end of the lifetime when the link should be refreshed
      this.keepAlifeTimeout = setTimeout(() => this.refreshLink(), (this.file.downloadUrlLifespan - requestBeforeExpire) * 1000)
    },
    async refreshLink () {
      const file = await feathers.service('file').get(this.file._id)
      this.link = file.downloadUrl
      this.keepLinkAlife()
    }
  },
  render () {
    return this.$scopedSlots.default({
      link: this.link
    })
  }
}
</script>

<style>

</style>
