<template>
  <modal
    v-if="show"
    title="Fahrzeugpool"
    :closable="false"
  >
    <template
      #body
    >
      <template v-if="!canUseCarPool">
        <p>Das Feature "Fahrzeugpool" ist für Sie noch nicht freigeschaltet. Wenden Sie sich an <span class="font-weight-bold">mobile</span> <span class="font-italic">GARANTIE</span>.</p>
      </template>
      <div
        v-if="!hasAdditionalData && canUseCarPool"
        class="row"
      >
        <div class="col-auto">
          <i class="fal fa-file-alt fa-4x text-primary" />
        </div>
        <div class="col">
          <h4 class="mb-2">
            Weitere Daten eingeben
          </h4>
          <p class="mb-2">
            Hier haben Sie die Möglichkeit weitere Daten zu ihrem Fahrzeug eintragen um Ihr Fahrzeug im Fahrzeugpool repräsentieren zu können oder ein Verkaufsschild generieren zu lassen.
          </p>

          <router-link
            :to="{name: 'CarAdditionalData', params: { carId: carId }}"
            class="btn btn-primary"
          >
            <i class="far fa-plus" /> weitere Daten hinzufügen
          </router-link>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="text-right">
        <button
          v-if="!canUseCarPool"
          type="button"
          class="btn btn-success text-white"
          @click="$emit('close')"
        >
          Verstanden
        </button>
        <button
          v-if="!hasAdditionalData && canUseCarPool"
          type="button"
          class="btn btn-secondary"
          @click="$emit('close')"
        >
          Abbrechen
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
export default {
  components: {
    Modal
  },
  model: {
    close: 'close'
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    carId: {
      type: String,
      default: ''
    },
    canUseCarPool: {
      type: Boolean,
      default: false
    },
    hasAdditionalData: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user']
    }
  }
}
</script>
