<template>
  <div>
    <!-- <go-to-order-modal
      v-if="showGoToOrderModal"
      :show="showGoToOrderModal"
      :params="params"
      @close="showGoToOrderModalClosed"
    /> -->
    <ca-card
      v-if="orders && orders.length > 0"
      class="mb-3"
    >
      <template #header>
        <div class="row">
          <div class="col">
            <h4 class="mb-0">
              {{ title }}
            </h4>
          </div>
          <div
            v-if="canCreateOrder && customer"
            class="col-auto"
          >
            <button
              class="btn hover-gray-1 px-2 py-0 mr-n2"
              @click="$router.push({name: 'HSSOrderCreate', params: {customer: customer} })"
            >
              <i class="far fa-plus fa-lg" />
            </button>
          </div>
        </div>
      </template>
      <template #default>
        <template v-if="orderAnfragen && orderAnfragen.length > 0">
          <h5 class="px-3 pt-2">
            Anfragen
          </h5>
        </template>
        <template v-for="(order, index) in orderAnfragen">
          <div
            :key="`Anfrage-${index}`"
            class="row rounded-lg align-items-center cursor-pointer hover-gray-1 px-3 py-2"
            @click="$router.push({name: 'HSSOrderDetail', params: {orderId: order._id}})"
          >
            <div class="col-auto pr-2">
              <div class="order-icon-wrapper bg-secondary">
                <i class="far fa-file-contract fa-2x text-primary" />
              </div>
            </div>
            <div class="col">
              <h5 class="mb-0 d-inline">
                {{ getProduct(order.product) }}
              </h5>
              <p class="mb-0">
                {{ order.classicCar.Hersteller }}
                {{ order.classicCar.Modellname }}
              </p>
            </div>
          </div>
        </template>
        <template v-if="orderAngebote && orderAngebote.length > 0">
          <hr class="m-0">
          <h5 class="px-3 pt-2">
            Angebote
          </h5>
        </template>
        <template v-for="(order, index) in orderAngebote">
          <div
            :key="`Angebot-${index}`"
            class="row rounded-lg align-items-center cursor-pointer hover-gray-1 px-3 py-2"
            @click="$router.push({name: 'HSSOrderDetail', params: {orderId: order._id}})"
          >
            <div class="col-auto pr-2">
              <div class="order-icon-wrapper bg-secondary">
                <i class="far fa-file-contract fa-2x text-primary" />
              </div>
            </div>
            <div class="col">
              <h5 class="mb-0 d-inline">
                {{ getProduct(order.product) }}
              </h5>
              <p class="mb-0">
                {{ order.classicCar.Hersteller }}
                {{ order.classicCar.Modellname }}
              </p>
            </div>
          </div>
        </template>
        <template v-if="orderAntraege && orderAntraege.length > 0">
          <hr class="m-0">
          <h5 class="px-3 pt-2">
            Anträge
          </h5>
        </template>
        <template v-for="(order, index) in orderAntraege">
          <div
            :key="`Antrag-${index}`"
            class="row rounded-lg align-items-center cursor-pointer hover-gray-1 px-3 py-2"
            @click="$router.push({name: 'HSSOrderDetail', params: {orderId: order._id}})"
          >
            <div class="col-auto pr-2">
              <div class="order-icon-wrapper bg-secondary">
                <i class="far fa-file-contract fa-2x text-primary" />
              </div>
            </div>
            <div class="col">
              <h5 class="mb-0 d-inline">
                {{ getProduct(order.product) }}
              </h5>
              <p class="mb-0">
                {{ order.classicCar.Hersteller }}
                {{ order.classicCar.Modellname }}
              </p>
            </div>
          </div>
        </template>
        <template v-if="orderVertraege && orderVertraege.length > 0">
          <hr class="m-0">
          <h5 class="px-3 pt-2">
            Verträge
          </h5>
        </template>
        <template v-for="(order, index) in orderVertraege">
          <div
            :key="`Vertrag-${index}`"
            class="row rounded-lg align-items-center cursor-pointer hover-gray-1 px-3 py-2"
            @click="$router.push({name: 'HSSOrderDetail', params: {orderId: order._id}})"
          >
            <div class="col-auto pr-2">
              <div class="order-icon-wrapper bg-secondary">
                <i class="far fa-file-contract fa-2x text-primary" />
              </div>
            </div>
            <div class="col">
              <h5 class="mb-0 d-inline">
                {{ getProduct(order.product) }}
              </h5>
              <p class="mb-0">
                {{ order.classicCar.Hersteller }}
                {{ order.classicCar.Modellname }}
              </p>
            </div>
          </div>
        </template>
      </template>
    </ca-card>
  </div>
</template>

<script>
import CaCard from '@/components/Card'
import getWarrentyNumber from '@/helpers/getWarrentyNumber'
import formatDate from '@/filters/formatDate.js'
import formatOrderStatus from '@/filters/formatOrderStatus.js'
import hsOrderProducts from '@/resources/enums/hsOrderProducts.js'
import hsOrderTypes from '@/resources/enums/hsOrderTypes'
export default {
  components: {
    CaCard
  },
  filters: {
    formatDate,
    formatOrderStatus
  },
  props: {
    orders: {
      default: () => [],
      type: Array
    },
    title: {
      default: 'Vorgänge',
      type: String
    },
    canCreateOrder: {
      default: true,
      type: Boolean
    },
    viewedOrder: {
      default: null,
      type: Object
    },

    /**
     * Object with user object. can be extended
     * example { car: { ... } }
     */
    params: {
      type: Object,
      default: undefined
    },
    customer: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      showGoToOrderModal: false
    }
  },
  computed: {
    orderAnfragen () {
      return this.orders.filter(el => el.hsOrderType === hsOrderTypes.ANFRAGE.api)
    },
    orderAngebote () {
      return this.orders.filter(el => el.hsOrderType === hsOrderTypes.ANGEBOT.api)
    },
    orderAntraege () {
      return this.orders.filter(el => el.hsOrderType === hsOrderTypes.ANTRAG.api)
    },
    orderVertraege () {
      return this.orders.filter(el => el.hsOrderType === hsOrderTypes.VERTRAG.api)
    }
  },
  methods: {
    getProduct (curProduct) {
      if (!curProduct) return
      const humanProduct = Object.values(hsOrderProducts).find(hsOrderProduct => hsOrderProduct.api === curProduct).human
      return humanProduct
    },
    getOrderType (order) {
      let orderTypeString = ''
      if (order.isKaufschutz) orderTypeString = 'KAUFSCHUTZ'
      else if (order.product.rate && order.product.rate.name && order.product.rate.rateType) orderTypeString = order.product.rate.rateType
      if (orderTypeString.length > 0) orderTypeString = ', ' + orderTypeString
      return orderTypeString
    },
    getWarrentyNumber,
    openGoToOrderModal () {
      this.showGoToOrderModal = true
    },
    showGoToOrderModalClosed () {
      this.showGoToOrderModal = false
    },
    getStatusClass (statusCode) {
      let statusClass = ''
      switch (statusCode) {
      case 0: statusClass = 'badge-primary'; break
      case 1: statusClass = 'badge-success'; break
      case 2:
      case 3:
      case 4: statusClass = 'badge-warning'; break
      }
      return statusClass
    },
    getRelationStatus (order) {
      if (order.ancestorId === this.$route.params.orderId) {
        return 'Verlängerung dieses Vertrags'
      } else if (this.viewedOrder && this.viewedOrder.ancestorId === order._id) {
        return 'Vorgänger'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.order-icon-wrapper{
  margin-right: 5px;
  margin-bottom: 10px;
  border-radius: 50%;
  $size: 40px;
  width: $size;
  height: $size;
  position: relative;
  i{
    position: absolute;
    right: -5px;
    bottom: -5px;
  }
}

</style>
