<template>
  <transition name="lightbox">
    <div
      class="lightbox-mask d-flex align-items-center justify-content-center"
      @click.self="close()"
    >
      <div
        class="mx-auto lightbox-container"
        :class="[{'sm': size === 'sm'}, {'lg': size === 'lg'}]"
      >
        <template
          v-if="images && images[slide]"
        >
          <div class="actions d-flex justify-content-end pt-5 pb-2">
            <button
              id="removeImage"
              class="btn btn-link text-white mr-2"
            >
              <i class="fas fa-trash-alt mr-2" /> Bild löschen
            </button>
            <b-popover
              target="removeImage"
              placement="bottom"
              title="Sind Sie sich sicher?"
              triggers="click blur"
            >
              <p>
                Das Bild wird dadurch unwiderruflich gelöscht!
              </p>
              <button
                class="btn btn-danger"
                @click="removeImage(slide)"
              >
                Bestätigen
              </button>
            </b-popover>
            <!-- <a
              :download="images[slide].downloadUrl"
              :href="images[slide].downloadUrl"
              class="btn btn-link text-white"
            >
              <i class="fas fa-download mr-2" /> Bild herunterladen
            </a> -->
          </div>
          <b-carousel
            id="carousel-1"
            ref="carousel"
            v-model="slide"
            :interval="0"
            controls
            indicators
          >
            <template v-for="(image, index) in images">
              <b-carousel-slide :key="index">
                <img
                  slot="img"
                  class="carousel-img"
                  :src="image.downloadUrl"
                >
              </b-carousel-slide>
            </template>
          </b-carousel>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Lightbox',
  props: {
    title: {
      type: String,
      default: ''
    },
    closable: {
      type: Boolean,
      default: true
    },
    images: {
      type: Array,
      default: () => []
    },
    currentImage: {
      type: Object,
      default: undefined
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  data: () => ({
    slide: 0
  }),
  mounted () {
    this.$refs.carousel.setSlide(this.currentImage.index)
  },
  methods: {
    close () {
      if (this.closable) {
        this.$emit('close')
      }
    },
    removeImage (index) {
      this.$refs.carousel.setSlide(index + 1)
      this.$emit('removeImage', index)
      close()
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.lightbox-mask {
  position: fixed;
  z-index: 1050;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s ease;
}

.lightbox-container {
    &:hover{
      .actions{
        background: linear-gradient(0deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%);
      }
    }
  position: relative;
  .actions{
    z-index: 1050;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .carousel{
    width: 100%;
    height: 100%;
    .carousel-inner{
      height: inherit;
      width: 100%;
      .carousel-item{
        height: inherit;
        width: 100%;
        background-color: $gray-6;
        img{
          object-fit: contain;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  width: 600px;
  &.lg{
    width: 80%;
    height: 80%;
  }
  &.sm{
    width: 300px;
  }
  transition: all .3s ease;
}

/*
 * The following styles are auto-applied to elements with
 * transition="lightbox" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the lightbox transition by editing
 * these styles.
 */

.lightbox-enter {
  opacity: 0;
}

.lightbox-leave-active {
  opacity: 0;
}

.lightbox-enter .lightbox-container,
.lightbox-leave-active .lightbox-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
