<template>
  <basic-form-group
    :id="id"
    :label-is-up="labelIsUp"
    :label="$attrs.label"
    :required="$attrs.required"
    :horizontal="$attrs.horizontal"
    :columns="$attrs.columns"
    :margin="$attrs.margin"
    :infotext="$attrs.infotext"
    :align-label-center="$attrs['align-label-center']"
  >
    <template slot="label">
      <slot name="label" />
    </template>
    <template slot="input">
      <div class="custom-file">
        <div
          v-if="uploadPending"
          class="upload-pending"
        >
          <b-progress
            :value="100"
            height="100%"
            class="w-100"
            variant="primary"
            :animated="true"
          />
        </div>
        <input
          id="customFile"
          ref="fileInput"
          v-validate="fileuploadValidation"
          :data-test="dataTest"
          type="file"
          :multiple="multiple"
          :accept="accept"
          class="custom-file-input"
          :disabled="uploadPending || disabled"
          :name="veeField"
          :data-vv-as="name"
          :class="{'is-invalid': veeFields[veeField] && veeFields[veeField].validated && veeFields[veeField].invalid}"
          @change="upload"
        >
        <label
          class="custom-file-label"
          for="customFile"
        >Datei<span v-if="multiple">(en)</span> hochladen
        </label>
      </div>
      <small
        v-if="$attrs.small"
        class="text-muted d-block"
      >{{ $attrs.small }}</small>
      <transition-group
        v-if="files.length && !hideFileList"
        name="user"
        tag="ul"
        class="list-group list-group-flush mt-3"
      >
        <li
          v-for="(file, fileIndex) of files"
          :key="file._id"
          class="list-group-item px-2 py-2"
        >
          <div
            class="form-row align-items-center"
          >
            <div class="col">
              <SignedDownloadLink
                v-if="file"
                :file="file"
              >
                <template #default="{link}">
                  <a
                    :download="file.name"
                    :href="link"
                  >{{ file.name }}</a>
                </template>
              </SignedDownloadLink>
            </div>
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-link p-0"
                @click="removeFileFromList(fileIndex)"
              >
                <i class="fas fa-trash text-danger" />
              </button>
            </div>
          </div>
        </li>
      </transition-group>
      <validation-feedback :name="veeField" />
    </template>
  </basic-form-group>
</template>

<style lang="scss" scoped>
.upload-pending{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
</style>

<script>
import feathers from '../../api'
import axios from 'axios'
import SignedDownloadLink from '@/components/SignedDownloadLink'
import basicComponentMixin from './basic-component-mixin.js'
export default {
  name: 'BasicFileUpload',
  components: {
    SignedDownloadLink
  },
  $_veeValidate: {
    // fetch the current value from the innerValue defined in the component data.
    value () {
      return this.model
    }
  },
  mixins: [basicComponentMixin],
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => {}
    },
    hideFileList: {
      type: Boolean,
      default: false
    },
    dataTest: {
      type: String,
      default: 'basic-file-upload'
    }
  },
  data: () => ({
    uploadPending: false
  }),
  computed: {
    files () {
      const files = []
      if (this.model) {
        if (Array.isArray(this.model)) {
          files.push(...this.model)
        } else {
          files.push(this.model)
        }
      }
      return files
    },
    fileuploadValidation () {
      return {
        ...this.getValidation,
        required: this.getValidation.required && this.files.length === 0
      }
    }
  },
  methods: {
    async upload (event) {
      if (this.uploadPending || !event.target.files || !event.target.files[0]) return
      try {
        this.uploadPending = true
        this.$emit('upload-pending', this.uploadPending)

        if (this.multiple) {
          const toUploadFiles = []
          for (const key in event.target.files) {
            if (typeof event.target.files[key] === 'object') {
              toUploadFiles.push(event.target.files[key])
            }
          }
          const files = await Promise.all(toUploadFiles.map(async (toUploadFile) => {
            const file = await this.uploadFile(toUploadFile)
            return file
          }))
          this.$emit('uploaded', files)
          if (!Array.isArray(this.model)) this.model = []
          this.model.push(...files)
        } else {
          const toUploadFile = event.target.files[0]
          const file = await this.uploadFile(toUploadFile)
          this.$emit('uploaded', file)
          this.model = file
        }
      } catch (error) {
        console.error(error)
      } finally {
        // reset fileInput
        this.$refs.fileInput.value = ''
        if (this.$refs.fileInput.value) {
          this.$refs.fileInput.type = 'text'
          this.$refs.fileInput.type = 'file'
        }
        this.uploadPending = false
        this.$emit('upload-pending', this.uploadPending)
      }
    },
    async uploadFile (toUploadFile) {
      const file = await feathers.service('file').create({
        name: toUploadFile.name,
        type: toUploadFile.type
      })
      await axios.put(file.uploadUrl, toUploadFile, this.config)
      return file
    },
    removeFileFromList (index) {
      if (this.multiple) {
        this.model.splice(index, 1)
        this.$emit('removed', index)
      } else {
        this.model = null
        this.$emit('removed')
      }
    }
  }
}
</script>
